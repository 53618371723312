import { IonContent, IonPage, IonSpinner } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router';

import Header from '@facilities/components/Header/Header';
import RetoolWidgetContainer from '@facilities/components/RetoolWidgetContainer/RetoolWidgetContainer';
import {
  REQUEST_DETAIL_WIDGET_URL,
  REQUESTS_LIST_WIDGET_URL,
} from '@facilities/constants/retoolConstants';
import UUID_V4_REGEX from '@facilities/constants/urlConstants';
import useGetFacility from '@facilities/hooks/useGetFacility';
import formatRetoolWidgetUrl from '@facilities/utils/utils-fn';
import styles from '@facilities/views/containers.module.css';
import RetoolWidget from '@facilities/views/RetoolWidget/RetoolWidget';
import useIsScreenXL from '@shared/hooks/useIsScreenXL';

type SelectRequestHandleDataType = { requestId: string };

enum RequestActionDataType {
  DISMISS = 'dismiss',
}

type RequestActionHandleDataType = { action: RequestActionDataType };

type LocationProps = {
  shouldReload?: boolean;
};

const Requests = () => {
  const { t } = useTranslation('app', { keyPrefix: 'facilities' });
  const history = useHistory();
  const location = useLocation<LocationProps>();

  const { facilityId } = useParams<{ facilityId: string; requestId: string }>();
  const { data: facility, isSuccess, isLoading } = useGetFacility(facilityId);

  // Note(nbdn): using history pathname is voluntary here to avoid re-mounting current route
  const requestId = history.location.pathname.split('/requests/')[1];

  const isScreenXL = useIsScreenXL();

  const initialRequestId = UUID_V4_REGEX.test(requestId) ? requestId : null;
  const [requestsListWidgetKey, setRequestsListWidgetKey] = useState<string>(
    new Date().toISOString(),
  );
  const [selectedRequestId, setSelectedRequestId] = useState<string | null>(initialRequestId);

  const shouldDisplayRequestInfo = !!selectedRequestId;

  const handleSelectRequest = ({ requestId: id }: SelectRequestHandleDataType) => {
    if (!!id) {
      setSelectedRequestId(id);
      const currentPath = history.location.pathname;
      const newPath = currentPath.replace(/\/requests.*/, `/requests/${id}`);
      history.push(newPath);
    }
  };

  const handleRequestAction = ({ action }: RequestActionHandleDataType) => {
    if (action === RequestActionDataType.DISMISS) {
      setSelectedRequestId(null);
      const currentPath = history.location.pathname;
      const newPath = currentPath.replace(/\/requests\/.*/, '/requests');
      history.push(newPath);
    }
  };

  useEffect(() => {
    const currentRoute = history.location.pathname;
    const isActiveRoute = !!currentRoute.match(`/facilities/${facilityId}/requests`);

    /* eslint-disable @typescript-eslint/no-unnecessary-condition */
    if (!!location.state?.shouldReload) {
      setRequestsListWidgetKey(new Date().toISOString());
      setSelectedRequestId(null);
      history.replace(currentRoute, { shouldReload: false });
    }

    if (!isActiveRoute) {
      return;
    }

    if (!requestId) {
      setSelectedRequestId(null);

      return;
    }

    if (!!requestId && requestId !== selectedRequestId) {
      setSelectedRequestId(requestId);
    }
  }, [requestId, selectedRequestId, history.location, facilityId, history, location.state]);

  const requestsListUrl = formatRetoolWidgetUrl(REQUESTS_LIST_WIDGET_URL, { facilityId });
  const requestsWidget = (
    <RetoolWidget
      key={requestsListWidgetKey}
      url={requestsListUrl}
      handleData={handleSelectRequest}
      data={{ selectedId: selectedRequestId }}
    />
  );

  const requestUrl = formatRetoolWidgetUrl(REQUEST_DETAIL_WIDGET_URL, {
    requestId: selectedRequestId,
    facilityId,
  });
  const requestWidget = <RetoolWidget url={requestUrl} handleData={handleRequestAction} />;

  const shouldShowRequestInfoBelowRequestsList = !isScreenXL;

  const getContent = () => {
    return (
      <IonContent className="ion-padding">
        <div className={styles.fullWidthContainer}>
          <RetoolWidgetContainer
            retoolWidget={requestsWidget}
            width={shouldDisplayRequestInfo ? 60 : 100}
          />
          {shouldDisplayRequestInfo && !shouldShowRequestInfoBelowRequestsList && (
            <RetoolWidgetContainer
              // Add key to prevent undesired behavior on navigation stack
              key={selectedRequestId}
              retoolWidget={requestWidget}
              width={40}
            />
          )}
        </div>

        {shouldDisplayRequestInfo && shouldShowRequestInfoBelowRequestsList && (
          <div className={styles.fullWidthContainer}>
            <RetoolWidgetContainer
              // Add key to prevent undesired behavior on navigation stack
              key={selectedRequestId}
              retoolWidget={requestWidget}
              width={100}
            />
          </div>
        )}
      </IonContent>
    );
  };

  return (
    <IonPage>
      <Header title={t('common.menu.requests')} facility={facility} />
      {isLoading && <IonSpinner />}
      {isSuccess && getContent()}
    </IonPage>
  );
};

export default Requests;
