import { Capacitor } from '@capacitor/core';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import MobileAppDownloadAlert from '@carers/components/MobileAppDownload/MobileAppDownloadAlert';
import RoundWaveContentHeader from '@carers/components/RoundWaveContentHeader/RoundWaveContentHeader';
import useGetUserAccount from '@carers/hooks/useGetUserAccount';
import { disableFrontChatbot, enableFrontChatbot } from '@carers/utils/FrontChatbot';
import CalendarMissionsByMonth from '@carers/views/Activity/components/MissionsByMonth/CalendarMissionsByMonth';
import UserTasksList from '@carers/views/Activity/components/UserTasks/UserTasksList';
import LogoMediflashLinear from '@shared/assets/images/logo-mediflash-linear.svg';
import Refresher from '@shared/components/Refresher/Refresher';
import { PLATFORM } from '@shared/constants/appConstants';

type UserTasksListRef = {
  refetch: () => Promise<void>;
};

const Activity = () => {
  const { t } = useTranslation('app');

  const platform = Capacitor.getPlatform();
  const isWebPlatform = platform === PLATFORM.WEB;

  const location = useLocation();
  const userTasksListRef = useRef<UserTasksListRef | null>(null);
  const calendarMissionsByMonthRef = useRef<UserTasksListRef | null>(null);

  const calendarSectionRef = useRef<HTMLDivElement | null>(null);

  const { data: userAccount } = useGetUserAccount();

  const refetch = async () => {
    await Promise.all([
      userTasksListRef.current?.refetch(),
      calendarMissionsByMonthRef.current?.refetch(),
    ]);
  };

  const scrollToCalendar = () => {
    if (calendarSectionRef.current) {
      setTimeout(() => {
        if (calendarSectionRef.current) {
          calendarSectionRef.current.scrollIntoView({
            behavior: 'smooth',
          });
        }
      }, 100);
    }
  };

  const enablingFrontChatbot = useCallback(async () => {
    if (userAccount) {
      await enableFrontChatbot(
        userAccount.email,
        userAccount.fullName,
        userAccount.frontAppChatbotUserHash,
      );
    }
  }, [userAccount]);

  useEffect(() => {
    if (/activity$/.test(location.pathname)) {
      enablingFrontChatbot().catch((err) => {
        throw err;
      });
    }

    return disableFrontChatbot;
  }, [location, userAccount]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle className="ion-hide-xl-up ion-text-center">
            <img src={LogoMediflashLinear} width="150" height="26" alt={t('shared.appName')} />
          </IonTitle>
          <IonTitle className="ion-hide-xl-down">{t('carers.activity.title')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="withBlob ion-padding">
        <Refresher refetch={refetch} />
        <RoundWaveContentHeader />
        <div className="stack">
          <UserTasksList ref={userTasksListRef} />
          {/* TODO @adelebrgx change shouldShowNotificationsMessage to true when B-169 is pushed to prod. */}
          {isWebPlatform && <MobileAppDownloadAlert shouldShowNotificationsMessage />}
          <div ref={calendarSectionRef}>
            <CalendarMissionsByMonth
              ref={calendarMissionsByMonthRef}
              onSelectedDayChange={scrollToCalendar}
            />
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Activity;
