enum HTTPSErrorStatusCode {
  BAD_REQUEST = 400,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
}

const genericErrorMessageContent = 'common.messages.errors.generic';

const GENERIC_STATUS_CODES: Record<HTTPSErrorStatusCode, string> = {
  [HTTPSErrorStatusCode.BAD_REQUEST]: genericErrorMessageContent,
  [HTTPSErrorStatusCode.FORBIDDEN]: 'common.messages.errors.forbidden',
  [HTTPSErrorStatusCode.NOT_FOUND]: 'common.messages.errors.notFound',
  [HTTPSErrorStatusCode.INTERNAL_SERVER_ERROR]: 'common.messages.errors.internalServer',
};

enum HTTPErrorCode {
  HTTP_INBOUND_DTO_VALIDATION = 'HTTP_INBOUND_DTO_VALIDATION',
  'AUTH.INVALID_SIGNIN_CODE_FOR_USER' = 'AUTH.INVALID_SIGNIN_CODE_FOR_USER',
  'AUTH.EXPIRED_SIGNIN_CODE' = 'AUTH.EXPIRED_SIGNIN_CODE',
  'AUTH.UNABLE_TO_SEND_SIGNIN_CODE_TO_USER' = 'AUTH.UNABLE_TO_SEND_SIGNIN_CODE_TO_USER',
  ACCEPTED_SCHEDULE_DOES_NOT_EXIST_ON_REQUEST = 'ACCEPTED_SCHEDULE_DOES_NOT_EXIST_ON_REQUEST',
  CARER_MISSING_DOCUMENTS_FOR_REQUEST = 'CARER_MISSING_DOCUMENTS_FOR_REQUEST',
  CANCELLED_REQUEST_CANNOT_BE_ACCEPTED_BY_CARER = 'CANCELLED_REQUEST_CANNOT_BE_ACCEPTED_BY_CARER',
  CARER_WITH_JOB_NOT_COVERED = 'CARER_WITH_JOB_NOT_COVERED',
  CARER_WITH_PENDING_CANCELLATION_JUSTIFICATION = 'CARER_WITH_PENDING_CANCELLATION_JUSTIFICATION',
  REQUEST_IS_NOT_VISIBLE_ON_CARER_APP = 'REQUEST_IS_NOT_VISIBLE_ON_CARER_APP',
  REQUEST_SHOULD_HAVE_ACTIVE_POSITIONED_CARER = 'REQUEST_SHOULD_HAVE_ACTIVE_POSITIONED_CARER',
  REQUEST_NOT_SPLITTABLE = 'REQUEST_NOT_SPLITTABLE',
  REQUEST_ALREADY_HAS_A_POSITIONED_CARER_ON_SOME_SCHEDULES = 'REQUEST_ALREADY_HAS_A_POSITIONED_CARER_ON_SOME_SCHEDULES',
  REQUEST_ALREADY_ACCEPTED = 'REQUEST_ALREADY_ACCEPTED',
  REQUEST_ALREADY_FULFILLED = 'REQUEST_ALREADY_FULFILLED',
  REQUEST_END_DATE_SHOULD_BE_ON_OR_AFTER_TODAY = 'REQUEST_END_DATE_SHOULD_BE_ON_OR_AFTER_TODAY',
  UNSUBSCRIBED_CARER = 'UNSUBSCRIBED_CARER',
  SCHEDULES_ALREADY_VALIDATED = 'SCHEDULES_ALREADY_VALIDATED',
  SCHEDULE_PROBLEM_ALREADY_REPORTED = 'SCHEDULE_PROBLEM_ALREADY_REPORTED',
}

const HTTP_ERROR_CODES_MESSAGES: Record<HTTPErrorCode, string> = {
  HTTP_INBOUND_DTO_VALIDATION: 'common.messages.errors.invalidFormat',
  // Auth
  'AUTH.INVALID_SIGNIN_CODE_FOR_USER': 'auth.OTP.messages.errors.invalidCode',
  'AUTH.EXPIRED_SIGNIN_CODE': 'auth.OTP.messages.errors.expiredCode',
  'AUTH.UNABLE_TO_SEND_SIGNIN_CODE_TO_USER': 'auth.login.messages.errors.errorOccurred',
  // Accept-schedules
  ACCEPTED_SCHEDULE_DOES_NOT_EXIST_ON_REQUEST: genericErrorMessageContent,
  CANCELLED_REQUEST_CANNOT_BE_ACCEPTED_BY_CARER:
    'requests.detail.messages.errors.unavailableMission',
  CARER_MISSING_DOCUMENTS_FOR_REQUEST:
    'requests.detail.messages.errors.carerMissingDocumentsForRequest',
  REQUEST_IS_NOT_VISIBLE_ON_CARER_APP: genericErrorMessageContent,
  REQUEST_SHOULD_HAVE_ACTIVE_POSITIONED_CARER: genericErrorMessageContent,
  REQUEST_NOT_SPLITTABLE: genericErrorMessageContent,
  REQUEST_ALREADY_HAS_A_POSITIONED_CARER_ON_SOME_SCHEDULES:
    'requests.detail.messages.errors.unavailableMission',
  REQUEST_ALREADY_ACCEPTED: 'requests.detail.messages.errors.unavailableMission',
  REQUEST_ALREADY_FULFILLED: 'requests.detail.messages.errors.unavailableMission',
  REQUEST_END_DATE_SHOULD_BE_ON_OR_AFTER_TODAY:
    'requests.detail.messages.errors.unavailableMission',
  // Requests
  UNSUBSCRIBED_CARER: 'requests.list.messages.errors.noJobForCarer',
  CARER_WITH_PENDING_CANCELLATION_JUSTIFICATION: 'requests.list.messages.errors.noJobForCarer',
  CARER_WITH_JOB_NOT_COVERED: 'requests.list.messages.errors.noJobForCarer',
  // Schedules-problem
  SCHEDULES_ALREADY_VALIDATED: genericErrorMessageContent,
  SCHEDULE_PROBLEM_ALREADY_REPORTED: genericErrorMessageContent,
};

export {
  GENERIC_STATUS_CODES,
  HTTP_ERROR_CODES_MESSAGES,
  HTTPErrorCode,
  type HTTPSErrorStatusCode,
};
