import { PLATFORM } from '@shared/constants/appConstants';

function delay(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

const isObjEmpty = (obj: Record<string, unknown>) => {
  return Object.keys(obj).length === 0;
};

const generateArrayOfRandomNumbers = (length: number) =>
  Array.from({ length }, () => Math.floor(Math.random() * 1_000_000) + 1);

const getPlatformFromUserAgent = (): PLATFORM | null => {
  const { userAgent } = navigator;

  if (/android/i.test(userAgent)) {
    return PLATFORM.ANDROID;
  }

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return PLATFORM.IOS;
  }

  return null;
};

const setDocumentMetaTag = (name: string, content: string) => {
  const metaTag = document.querySelector<HTMLMetaElement>(`meta[name=${name}]`);

  if (metaTag) {
    metaTag.setAttribute('content', content);
  } else {
    const newMetaTag = document.createElement('meta');
    newMetaTag.setAttribute('name', name);
    newMetaTag.setAttribute('content', content);
    document.getElementsByTagName('head')[0].appendChild(newMetaTag);
  }
};

export {
  delay,
  generateArrayOfRandomNumbers,
  getPlatformFromUserAgent,
  isObjEmpty,
  setDocumentMetaTag as setMetaTag,
};
