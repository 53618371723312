import { IonContent, IonPage, IonTitle } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import { useTranslation } from 'react-i18next';

import AuthHeader from '@carers/views/Auth/components/AuthHeader';
import ImgRedirectToStore from '@shared/assets/images/status/redirect-to-store.svg';
import BlankSlate from '@shared/components/BlankSlate/BlankSlate';
import { PLATFORM } from '@shared/constants/appConstants';
import { STORE_LINK } from '@shared/constants/urlConstants';
import { getPlatformFromUserAgent } from '@shared/utils/utils-fn';

const RedirectToStoreBlocker = () => {
  const { t } = useTranslation('app', {
    keyPrefix: 'carers.common.messages.redirectToStoreBlocker',
  });

  const redirectToStore = () => {
    const platform = getPlatformFromUserAgent();

    if (!platform) {
      const error = new Error("Couldn't determine platform from user agent");

      Sentry.captureException(error);

      throw error;
    }

    const storeUrl = platform === PLATFORM.IOS ? STORE_LINK.IOS : STORE_LINK.ANDROID;

    window.location.href = storeUrl;
  };

  return (
    <IonPage>
      <AuthHeader />

      <IonContent>
        <BlankSlate
          action={redirectToStore}
          actionName={t('button')}
          image={ImgRedirectToStore}
          detail={{
            title: t('details.title'),
            message: (
              <ol>
                <li>{t('details.message.step_1')}</li>
                <li>{t('details.message.step_2')}</li>
                <li>{t('details.message.step_3')}</li>
              </ol>
            ),
          }}
          message={<IonTitle>{t('title')}</IonTitle>}
          notes={t('notes')}
        />
      </IonContent>
    </IonPage>
  );
};

export default RedirectToStoreBlocker;
