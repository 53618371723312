import { IonButton, IonContent, IonFooter, IonLabel, IonToolbar, useIonRouter } from '@ionic/react';
import { AxiosError } from 'axios';
import { FormEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { ANALYTICS_LOG_EVENTS } from '@carers/utils/Analytics.ts';
import {
  isCarerMissingRequiredDocumentsForRequest,
  isRequestCancelled,
  isRequestUnableToValidate,
  isRequestUnavailable,
} from '@carers/views/Requests/utils/requestErrors';
import ErrorImage from '@shared/assets/images/status/generic-error.svg';
import SuccessImage from '@shared/assets/images/status/generic-success.svg';
import NetworkErrorImage from '@shared/assets/images/status/network-error.svg';
import Alert, { AlertProps } from '@shared/components/Alert/Alert';
import BlankSlate from '@shared/components/BlankSlate/BlankSlate';
import { GenericAxiosResponseError } from '@shared/HttpInterceptors/GenericAxiosResponseError';
import { LabelValue } from '@shared/models/LabelValue';
import { logEvent } from '@shared/utils/Analytics';

type ActionResultType = {
  message?: string;
  action?: () => void;
  actionName?: string;
  image?: string;
  alertTitle?: string;
  alertMessage?: string;
  typeAlert: AlertProps['type'];
  missingRequiredDocuments?: LabelValue<string>[];
};

type ActionResultProps = {
  handleSubmitAcceptance: (event?: FormEvent<HTMLFormElement>) => Promise<void>;
  dismissAcceptanceModal: () => Promise<unknown>;
  isError: boolean;
  missingRequiredDocuments: LabelValue<string>[];
  missionId?: string;
  error?: GenericAxiosResponseError;
};

const ActionResult = ({
  handleSubmitAcceptance,
  dismissAcceptanceModal,
  missionId,
  isError = false,
  error,
  missingRequiredDocuments,
}: ActionResultProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'carers.requests.detail' });
  const router = useIonRouter();

  const goToRequests = async () => {
    await dismissAcceptanceModal();
    router.push('/carers/requests', 'back');
  };

  const goToMissionDetail = async () => {
    await dismissAcceptanceModal();
    router.push(`/carers/missions/${missionId}`, 'back', 'replace');
  };

  const goToProfile = async () => {
    await dismissAcceptanceModal();
    router.push(`/carers/profile`, 'back');
  };

  const missingRequiredDocumentLabelLists =
    missingRequiredDocuments.length > 0 ? (
      <ul style={{ margin: '0' }}>
        {missingRequiredDocuments.map((missingDocument) => {
          return <li key={missingDocument.value}>{missingDocument.label}</li>;
        })}
      </ul>
    ) : (
      ''
    );

  const getActionResultConfigOnError = ({
    code,
    response,
    errorMessage,
  }: GenericAxiosResponseError): ActionResultType => {
    if (response?.data.error?.code) {
      const actionResult: Partial<ActionResultType> = {
        message: errorMessage,
        image: ErrorImage,
        actionName: t('actions.showRequests'),
        action: goToRequests,
      };

      if (isCarerMissingRequiredDocumentsForRequest(response.data.error.code)) {
        void logEvent(ANALYTICS_LOG_EVENTS.CARER_MISSING_REQUIRED_DOCUMENTS_FOR_REQUEST);

        return {
          ...actionResult,
          alertTitle: t('alerts.carerMissingRequiredDocumentsForRequest.title'),
          alertMessage: t('alerts.carerMissingRequiredDocumentsForRequest.message'),
          typeAlert: 'danger',
          missingRequiredDocuments,
          actionName: t('actions.showProfile'),
          action: goToProfile,
        };
      }

      if (isRequestUnavailable(response.data.error.code)) {
        void logEvent(ANALYTICS_LOG_EVENTS.ACCEPT_UNAVAILABLE_REQUEST);

        return {
          ...actionResult,
          alertTitle: t('alerts.missionNotAvailableTitle'),
          alertMessage: t('alerts.missionNotAvailableText'),
          typeAlert: 'info',
        };
      }

      if (isRequestCancelled(response.data.error.code)) {
        return {
          ...actionResult,
          alertTitle: t('alerts.cancelledRequestError.title'),
          alertMessage: t('alerts.cancelledRequestError.message'),
          typeAlert: 'info',
        };
      }

      if (isRequestUnableToValidate(response.data.error.code)) {
        return {
          ...actionResult,
          alertTitle: t('alerts.missionValidationErrorHappened.title'),
          alertMessage: t('alerts.missionValidationErrorHappened.message'),
          typeAlert: 'danger',
        };
      }
    }

    return {
      message: errorMessage,
      image: code === AxiosError.ERR_NETWORK ? NetworkErrorImage : ErrorImage,
      actionName: t('actions.retry'),
      typeAlert: 'info',
      action: handleSubmitAcceptance,
    };
  };

  const configActionResult: ActionResultType =
    isError && error
      ? getActionResultConfigOnError(error)
      : {
          message: t('successAcceptRequest'),
          image: SuccessImage,
          alertTitle: t('alerts.validationInProgress.title'),
          alertMessage: t('alerts.validationInProgress.message'),
          actionName: t('actions.visualizeMission'),
          typeAlert: 'info',
          action: goToMissionDetail,
        };

  return (
    <>
      <IonContent>
        <BlankSlate image={configActionResult.image}>
          <h5 color="primary" className="ion-no-margin">
            {configActionResult.message}
          </h5>
          {(configActionResult.alertTitle || configActionResult.alertMessage) && (
            <Alert type={configActionResult.typeAlert} title={configActionResult.alertTitle}>
              <IonLabel>
                {configActionResult.alertMessage}
                {!!configActionResult.missingRequiredDocuments && missingRequiredDocumentLabelLists}
              </IonLabel>
            </Alert>
          )}
        </BlankSlate>
      </IonContent>
      <IonFooter>
        <IonToolbar color="light">
          <div slot="primary" className="stack ion-padding">
            <IonButton fill="outline" expand="block" onClick={configActionResult.action}>
              {configActionResult.actionName}
            </IonButton>
          </div>
        </IonToolbar>
      </IonFooter>
    </>
  );
};

export { ActionResult };
export type { ActionResultProps };
