const BASE_URL = 'https://mediflash.fr';

const BEST_PRACTICES_SHEET_URL = `${BASE_URL}/blog-mediflash/bonnes-pratiques-soignants-mediflash/`;
const CORPORATE_WEBSITE_CARERS_SIGNIN_URL = `${BASE_URL}/inscription-soignants`;
const SPONSORSHIP_LINK = `${BASE_URL}/parrainage`;
const FAQ_LINK = `${BASE_URL}/faq`;
const FEEDBACK_LINK = `${BASE_URL}/retours-soignants-app`;
const CGU_LINK = `${BASE_URL}/conditions-generales-d-utilisation`;

enum STORE_LINK {
  ANDROID = 'https://play.google.com/store/apps/details?id=fr.mediflash.app&hl=fr',
  IOS = 'https://apps.apple.com/fr/app/mediflash-renfort-soignant/id6451417947',
}

export {
  BEST_PRACTICES_SHEET_URL,
  CORPORATE_WEBSITE_CARERS_SIGNIN_URL,
  SPONSORSHIP_LINK,
  FAQ_LINK,
  FEEDBACK_LINK,
  CGU_LINK,
  STORE_LINK,
};
