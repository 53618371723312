import {
  IonButton,
  IonCard,
  IonCardContent,
  IonInput,
  IonLabel,
  IonLoading,
  useIonToast,
} from '@ionic/react';
import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useSiretMask from '@carers/views/Profile/hooks/useSiretMask';
import {
  UserUpdateInformation,
  useUpdateProfessionalInformation,
} from '@carers/views/Profile/hooks/useUpdateProfessionalInformation';
import styles from '@carers/views/Profile/Profile.module.css';
import CheckIcon from '@shared/assets/icons/check-circle.svg';
import Alert from '@shared/components/Alert/Alert';
import { LabelValue } from '@shared/models/LabelValue';

type ProfessionalInformationFormProps = {
  missingRequiredDocuments: LabelValue<string>[];
  updateDocumentsFormUrl: string;
};

const ProfessionalInformationForm = ({
  missingRequiredDocuments,
  updateDocumentsFormUrl,
}: ProfessionalInformationFormProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'carers.profile' });
  const [presentToast] = useIonToast();
  const siretMask = useSiretMask();

  const isMissingSiret = !!missingRequiredDocuments.find(
    (missingDocument) => missingDocument.value === 'SIRET_ID',
  );

  const hasMissingDocumentsOtherThanSiret =
    missingRequiredDocuments.filter((document) => document.value !== 'SIRET_ID').length > 0;

  const missingRequiredDocumentLabelLists = (
    <ul style={{ margin: '0' }}>
      {missingRequiredDocuments.map((missingDocument) => {
        return <li key={missingDocument.value}>{missingDocument.label}</li>;
      })}
    </ul>
  );

  const [professionalInformation, setProfessionalInformation] = useState<UserUpdateInformation>();
  const {
    mutateAsync: professionalInformationMutation,
    isError,
    error,
    isPending,
  } = useUpdateProfessionalInformation();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      await professionalInformationMutation(professionalInformation!);
      void presentToast({
        message: t('messages.success.SIRETsent'),
        duration: 2000,
        position: 'bottom',
        color: 'success',
        icon: CheckIcon,
        positionAnchor: 'main-tab-bar',
      });

      return null;
    } catch {
      return null;
    }
  };

  const onIonInputChange = (event: Event) => {
    const target = event.target as HTMLInputElement;

    setProfessionalInformation({
      ...professionalInformation,
      siretId: target.value,
    });
  };

  return (
    <>
      <Alert type="danger" title={t('alerts.incompleteProfileTitle')}>
        <IonLabel>
          {t('alerts.incompleteProfileText')}
          {missingRequiredDocumentLabelLists}
        </IonLabel>
      </Alert>

      <IonLoading isOpen={isPending} message={t('alerts.processingTitle')} />

      {isMissingSiret && (
        <IonCard>
          <IonCardContent className="stack">
            <p>{t('fillSIRET')}</p>
            <form onSubmit={handleSubmit} id="sendSiretForm" className="stack">
              <IonInput
                className={`${isError ? 'ion-invalid ion-touched' : ''}`}
                ref={async (siretInput) => {
                  if (siretInput) {
                    const input = await siretInput.getInputElement();
                    siretMask(input);
                  }
                }}
                inputmode="numeric"
                label={t('fillSIRETPlaceholder')}
                labelPlacement="floating"
                required
                fill="outline"
                errorText={isError ? error.errorMessage : undefined}
                onIonInput={(e) => onIonInputChange(e)}
              />
              <IonButton expand="block" type="submit" form="sendSiretForm">
                {t('actions.send')}
              </IonButton>
            </form>
          </IonCardContent>
        </IonCard>
      )}

      {hasMissingDocumentsOtherThanSiret && (
        <IonCard>
          <IonCardContent className={styles.availabilities}>
            <p className="title" style={{ marginBottom: '16px' }}>
              {t('missingDocuments')}
            </p>
            <IonButton expand="block" href={updateDocumentsFormUrl} target="_blank">
              {t('actions.sendMissingDocuments')}
            </IonButton>
          </IonCardContent>
        </IonCard>
      )}
    </>
  );
};

export default ProfessionalInformationForm;
