import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCheckbox,
  IonContent,
  IonFooter,
  IonLabel,
  IonLoading,
  IonToolbar,
} from '@ionic/react';
import { Dispatch, FormEvent, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import MissionScheduleDates from '@carers/components/MissionScheduleDates/MissionScheduleDates';
import { ModalTitle } from '@carers/components/Modal';
import PaymentCard from '@carers/components/PaymentCard/PaymentCard';
import ScrollableContentButton from '@carers/components/ScrollableContentButton/ScrollableContentButton';
import { ANALYTICS_LOG_EVENTS } from '@carers/utils/Analytics.ts';
import useAcceptSchedules from '@carers/views/Requests/hooks/useAcceptSchedules';
import useIsIonContentOverflow from '@carers/views/Requests/hooks/useIsIonContentOverflow';
import { Request } from '@carers/views/Requests/models/Requests';
import { ActionResult } from '@carers/views/Requests/RequestDetail/RequestAcceptance/ActionResult/ActionResult';
import ConflictingDates from '@carers/views/Requests/RequestDetail/RequestAcceptance/ConflictingDates/ConflictingDates';
import Alert from '@shared/components/Alert/Alert';
import { CONTRACTS } from '@shared/constants/missionsConstants';
import { logEvent } from '@shared/utils/Analytics';

import styles from './RequestAcceptance.module.css';

type RequestsAcceptanceProps = {
  request: Request;
  dismissAcceptanceModal: () => Promise<unknown>;
  setIsRemunerationNegotiationModalOpen: Dispatch<SetStateAction<boolean>>;
};

const RequestAcceptance = ({
  request,
  dismissAcceptanceModal,
  setIsRemunerationNegotiationModalOpen,
}: RequestsAcceptanceProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'carers.requests.detail' });
  const ionContentRef = useRef<HTMLIonContentElement>(null);
  const [isAcceptanceConditionChecked, setIsAcceptanceConditionChecked] = useState(false);
  const [dateSchedulesIds, setDateSchedulesIds] = useState<string[]>([]);

  const isIonContentOverflow = useIsIonContentOverflow(ionContentRef);

  const {
    data: missionId,
    mutate: acceptSchedules,
    isPending,
    error,
    isError,
    isSuccess,
  } = useAcceptSchedules();

  const isSettled = isError || isSuccess;

  const isIndependentRequest = request.contract.value === CONTRACTS.INDEPENDENT;

  useEffect(() => {
    // Initialize dateScheduleIds with all schedule IDs if isSplittable is false
    if (!request.isSplittable) {
      setDateSchedulesIds(request.daySchedules.map((schedule) => schedule.id));
    }
  }, [request.isSplittable, request.daySchedules]);

  const canAcceptMission = dateSchedulesIds.length > 0 && isAcceptanceConditionChecked;

  const handleSubmitAcceptance = async (event?: FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    void logEvent(ANALYTICS_LOG_EVENTS.ACCEPT_REQUEST_BUTTON_CLICK);
    acceptSchedules({ requestId: request.id, dateSchedulesIds });
  };

  return (
    <>
      <IonLoading isOpen={isPending} message={t('validation')} />

      {isSettled ? (
        <ActionResult
          handleSubmitAcceptance={handleSubmitAcceptance}
          dismissAcceptanceModal={dismissAcceptanceModal}
          missionId={missionId}
          isError={isError}
          error={error ?? undefined}
          missingRequiredDocuments={request.missingRequiredDocuments}
        />
      ) : (
        <>
          <IonContent ref={ionContentRef} className="ion-padding">
            <ModalTitle id="acceptance-modal-title">{t('chooseMissionDates')}</ModalTitle>

            <form
              onSubmit={handleSubmitAcceptance}
              id="acceptanceRequestsForm"
              className="ion-padding-top stack"
            >
              <IonCard>
                <IonCardContent>
                  <MissionScheduleDates
                    daySchedules={request.daySchedules}
                    isEditable
                    isSplittable={request.isSplittable}
                    checkedScheduleIds={dateSchedulesIds}
                    onCheckboxChange={setDateSchedulesIds}
                  />
                </IonCardContent>
              </IonCard>
              <ConflictingDates daySchedules={request.daySchedules} />

              {!request.isSplittable && (
                <Alert type="info" title={t('alerts.sameCaregiverRequired.title')}>
                  <IonLabel>{t('alerts.sameCaregiverRequired.message')}</IonLabel>
                </Alert>
              )}

              <IonCheckbox
                labelPlacement="end"
                alignment="center"
                class="ion-text-wrap"
                className={`${styles.conditionCheckbox}`}
                onIonChange={(e) => setIsAcceptanceConditionChecked(e.detail.checked)}
              >
                <span className="ion-text-wrap">{t('paymentConditions')}</span>
              </IonCheckbox>
            </form>
            {isIndependentRequest && (
              <IonButton
                expand="block"
                fill="clear"
                id="open-remuneration-negotiation-modal"
                onClick={() => setIsRemunerationNegotiationModalOpen(true)}
              >
                {t('negotiateRemuneration.actions.negotiateRemuneration')}
              </IonButton>
            )}

            {isIonContentOverflow && (
              <ScrollableContentButton ionContentRef={ionContentRef.current} />
            )}
          </IonContent>

          <IonFooter>
            <IonToolbar color="light">
              <div slot="primary" className="stack ion-padding">
                <PaymentCard mission={request} selectedDateSchedulesIds={dateSchedulesIds} />

                <IonButton fill="outline" expand="block" onClick={dismissAcceptanceModal}>
                  {t('actions.cancel')}
                </IonButton>

                <IonButton
                  expand="block"
                  type="submit"
                  form="acceptanceRequestsForm"
                  disabled={!canAcceptMission}
                >
                  {t('actions.acceptMission')}
                </IonButton>
              </div>
            </IonToolbar>
          </IonFooter>
        </>
      )}
    </>
  );
};

export default RequestAcceptance;
