import {
  IonContent,
  IonIcon,
  IonLabel,
  IonPage,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route } from 'react-router';

import useGetFacility from '@facilities/hooks/useGetFacility';
import FacilitiesRouteId from '@facilities/routes/route';
import { FACILITIES_MAIN_ROUTES, FACILITIES_TABS_ROOT_ROUTES } from '@facilities/routes/routes';
import { ANALYTICS_LOG_EVENTS } from '@facilities/utils/Analytics';
import initBrevoTracker from '@facilities/utils/BrevoTracker';
import initUsetiful from '@facilities/utils/Usetiful';
import Carers from '@facilities/views/Carers/Carers';
import Feedback from '@facilities/views/Feedback/Feedback';
import Home from '@facilities/views/Home/Home';
import NewRequest from '@facilities/views/NewRequest/NewRequest';
import NoFacilityErrorPage from '@facilities/views/NoFacilityFoundErrorPage/NoFacilityErrorPage';
import Preferences from '@facilities/views/Preferences/Preferences';
import Requests from '@facilities/views/Requests/Requests';
import Tasks from '@facilities/views/Tasks/Tasks';
import BlankSlate from '@shared/components/BlankSlate/BlankSlate';
import useDisplayTabBar from '@shared/hooks/useDisplayTabBar';
import useIsScreenXL from '@shared/hooks/useIsScreenXL';
import { logEvent } from '@shared/utils/Analytics';
import styles from '@shared/views/tabs/TabsOutlet.module.css';
import XLScreenSideHeader from '@shared/views/XLScreenSideHeader/XLScreenSideHeader';

const routesComponents: Record<FacilitiesRouteId, React.FC> = {
  home: Home,
  requests: Requests,
  newRequest: NewRequest,
  tasks: Tasks,
  requestDetail: Requests,
  taskDetail: Tasks,
  carers: Carers,
  preferences: Preferences,
  feedback: Feedback,
};

type Props = {
  match: {
    params: {
      facilityId: string;
    };
  };
};

const FacilitiesTabsOutlet = ({ match }: Props) => {
  const { facilityId } = match.params;

  if (!facilityId) {
    throw new Error('Undefined facilityId');
  }

  const { data: facility, isSuccess, isLoading, isError } = useGetFacility(facilityId);

  const { t } = useTranslation();

  const isScreenXL = useIsScreenXL();
  const isTabBarVisible = useDisplayTabBar(isScreenXL);

  useEffect(() => {
    const cleanupBrevoTracker = initBrevoTracker();
    const cleanupUsetiful = initUsetiful();

    const cleanup = () => {
      cleanupBrevoTracker();
      cleanupUsetiful();
    };

    return cleanup;
  }, []);

  if (isLoading) {
    return (
      <IonPage>
        <IonContent>
          <BlankSlate message={t('shared.messages.info.loading')} />
        </IonContent>
      </IonPage>
    );
  }

  if (isError) {
    void logEvent(ANALYTICS_LOG_EVENTS.AUTH_FAILURE);
  }

  return (
    <>
      {isScreenXL && <XLScreenSideHeader />}
      <IonTabs className={styles.ionTabs}>
        <IonRouterOutlet animated={!isScreenXL}>
          {FACILITIES_MAIN_ROUTES.map((tab) => (
            <Route
              exact={tab.exact}
              path={tab.path}
              component={!!facility && isSuccess ? routesComponents[tab.id] : NoFacilityErrorPage}
              key={tab.id}
            />
          ))}
        </IonRouterOutlet>
        <IonTabBar
          id="main-tab-bar"
          slot="bottom"
          className={`${styles.ionTabBar} ${isTabBarVisible ? '' : 'ion-hide'}`}
        >
          {FACILITIES_TABS_ROOT_ROUTES.map((tab) => (
            <IonTabButton
              key={tab.id}
              tab={tab.id}
              href={tab.href(facilityId)}
              className={styles.ionTabButton}
            >
              <IonIcon aria-hidden="true" src={tab.icon} className={styles.ionIcon} />
              <IonLabel aria-label={t(tab.label)} role="navigation">
                {t(tab.label)}
              </IonLabel>
            </IonTabButton>
          ))}
        </IonTabBar>
      </IonTabs>
    </>
  );
};

export default FacilitiesTabsOutlet;
