import { IonButton, IonLabel } from '@ionic/react';
import { HTMLAttributes, PropsWithChildren, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import Alert from '@shared/components/Alert/Alert';

import styles from './BlankSlate.module.css';

type BlankSlateProps = {
  message?: string | ReactNode;
  notes?: string;
  action?: () => void;
  actionName?: string;
  detail?: { title: string; message: string | ReactNode };
  image?: string;
} & HTMLAttributes<HTMLDivElement>;

const BlankSlate = ({
  action,
  actionName,
  detail,
  message,
  notes,
  image,
  className,
  children,
  style,
}: PropsWithChildren<BlankSlateProps>) => {
  const { t } = useTranslation('app', { keyPrefix: 'shared' });

  const shouldShowDetail = !!detail;
  const shouldShowAction = !!action;
  const shouldShowNotes = !!notes;

  return (
    <div className={`${className || ''} ${styles.blankSlateContainer}`} style={style}>
      {image && <img src={image} className={styles.blankSlateImage} alt="" />}
      <IonLabel color="primary" className={styles.ionLabel}>
        {message}
      </IonLabel>
      {shouldShowDetail && (
        <div className={styles.detail}>
          <Alert type="info" title={detail.title}>
            <IonLabel>{detail.message}</IonLabel>
          </Alert>
        </div>
      )}
      {shouldShowNotes && (
        <IonLabel color="primary" className={styles.ionLabelNotes}>
          {notes}
        </IonLabel>
      )}
      {shouldShowAction && (
        <IonButton style={{ width: '100%' }} onClick={action}>
          {actionName ?? t('actions.retry')}
        </IonButton>
      )}
      {children}
    </div>
  );
};

export default BlankSlate;
