import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonChip,
  IonIcon,
  IonLabel,
  IonText,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';

import MissionStatusChip from '@carers/components/CardMission/MissionStatusChip';
import { ANALYTICS_LOG_EVENTS } from '@carers/utils/Analytics.ts';
import { Mission } from '@carers/views/Missions/models/Missions';
import ContentPasteIcon from '@shared/assets/icons/content_paste.svg';
import ErrorOutlineIcon from '@shared/assets/icons/error_outline.svg';
import FileIcon from '@shared/assets/icons/file.svg';
import PhoneIcon from '@shared/assets/icons/local_phone.svg';
import LocationIcon from '@shared/assets/icons/location.svg';
import { BEST_PRACTICES_SHEET_URL } from '@shared/constants/urlConstants';
import { logEvent } from '@shared/utils/Analytics';

import styles from '../../MissionDetail.module.css';

type MissionFacilityCardProps = {
  mission: Mission;
};

const MissionFacilityCard = ({ mission }: MissionFacilityCardProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'carers.missions.detail' });

  const handleFacilityCardButtonClick = async () => {
    const facilityCardUrl = mission.facility.cardUrl;

    void logEvent(ANALYTICS_LOG_EVENTS.FACILITY_CARD_BUTTON_CLICK);

    if (!facilityCardUrl) {
      return;
    }

    window.location.href = facilityCardUrl;
  };

  return (
    <IonCard>
      <IonCardHeader className={styles.ionCardHeader}>
        <div>
          <MissionStatusChip label={mission.status.label} status={mission.status.value} />
          <IonChip color="primary" disabled>
            {mission.contract.label}
          </IonChip>
        </div>
        {mission.tasksToCompleteCount > 0 && (
          <IonChip color="danger" disabled>
            <IonIcon icon={ErrorOutlineIcon} />
            <IonLabel>
              {t('tasksToCompleteCount', { count: mission.tasksToCompleteCount })}
            </IonLabel>
          </IonChip>
        )}
        <h5>{mission.facility.name}</h5>
      </IonCardHeader>
      <IonCardContent>
        <IonText>{`${mission.facility.address}, ${mission.facility.postalCode} ${mission.facility.city}`}</IonText>
        <div className="stack stack-horizontal ion-margin-top ion-justify-content-center ion-wrap ion-align-items-center">
          <IonButton
            fill="clear"
            className="button-link"
            href={mission.facility.googleMapsUrl}
            target="_blank"
          >
            <IonIcon aria-hidden="true" slot="start" icon={LocationIcon} />
            {t('actions.locateFacility')}
          </IonButton>
          {!!mission.facility.cardUrl && (
            <IonButton
              fill="clear"
              className="button-link"
              onClick={handleFacilityCardButtonClick}
              target="_blank"
            >
              <IonIcon aria-hidden="true" slot="start" icon={FileIcon} />
              {t('actions.facilitySheet')}
            </IonButton>
          )}
          <IonButton
            fill="clear"
            className="button-link"
            href={BEST_PRACTICES_SHEET_URL}
            target="_blank"
          >
            <IonIcon aria-hidden="true" slot="start" icon={ContentPasteIcon} />
            {t('actions.bestPracticesSheet')}
          </IonButton>

          <IonButton fill="outline" href={`tel:${mission.facility.switchboardPhone}`}>
            <IonIcon aria-hidden="true" slot="start" icon={PhoneIcon} />
            {t('actions.contactFacility')}
          </IonButton>
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default MissionFacilityCard;
