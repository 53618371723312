import { IonHeader, IonLabel, IonTitle, IonToolbar } from '@ionic/react';

import styles from '@facilities/components/Header/Header.module.css';
import { Facility } from '@facilities/services/GetFacility';
import { Logo, ShadeEnum } from '@shared/components/Logo/Logo';

type HeaderProps = {
  title: string;
  facility?: Facility;
};

const Header = ({ title, facility }: HeaderProps) => {
  return (
    <IonHeader>
      <IonToolbar>
        <IonTitle className="ion-hide-xl-up ion-text-center">
          <Logo alt={title} aria-hidden shade={ShadeEnum.light} />
        </IonTitle>
        <div className="stack stack-horizontal">
          <IonTitle>{title}</IonTitle>
          {!!facility && (
            <div className={styles.facilityName}>
              <IonLabel className={`ion-text-right ${styles.facilityNameLabel}`}>
                {facility.name}
              </IonLabel>
            </div>
          )}
        </div>
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
