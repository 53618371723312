import { IonCheckbox, IonItem, IonLabel, IonList } from '@ionic/react';
import React, { useCallback } from 'react';

import {
  MissionScheduleBreakDuration,
  MissionScheduleDate,
  MissionScheduleTime,
} from '@carers/components/MissionScheduleDates/MissionScheduleDateDetail';
import { DayScheduleLight, DayScheduleRequest } from '@carers/models/RequestAndMission';
import { List, ListActionKind, ListItem, listReducer } from '@shared/utils/ListReducer';

import styles from './MissionScheduleDates.module.css';

interface MissionScheduleDatesProps {
  daySchedules: DayScheduleLight[] | DayScheduleRequest[];
  isEditable?: boolean;
  isSplittable?: boolean;
  checkedScheduleIds?: List;
  onCheckboxChange?: (checkedIds: List) => void;
}

const MAXIMUM_GAP_BETWEEN_REQUEST_AND_CARER_MISSIONS_IN_MINUTES = 60;
const MILLISECONDS_PER_MINUTE = 60 * 1000;

const isScheduleWithConflictingDaySchedules = (
  daySchedule: DayScheduleLight | DayScheduleRequest,
): daySchedule is DayScheduleRequest =>
  'conflictingDaySchedules' in daySchedule && daySchedule.conflictingDaySchedules.length > 0;

const haveMoreThanOneHourDifference = (
  daySchedule: DayScheduleLight | DayScheduleRequest,
  conflictingDaySchedule: DayScheduleLight | DayScheduleRequest,
) => {
  const dayScheduleStartMinusMaximumGap = new Date(daySchedule.start).setTime(
    new Date(daySchedule.start).getTime() -
      MAXIMUM_GAP_BETWEEN_REQUEST_AND_CARER_MISSIONS_IN_MINUTES * MILLISECONDS_PER_MINUTE,
  );
  const dayScheduleEndPlusMaximumGap = new Date(daySchedule.end).setTime(
    new Date(daySchedule.end).getTime() +
      MAXIMUM_GAP_BETWEEN_REQUEST_AND_CARER_MISSIONS_IN_MINUTES * MILLISECONDS_PER_MINUTE,
  );

  return (
    dayScheduleStartMinusMaximumGap.valueOf() >= new Date(conflictingDaySchedule.end).valueOf() ||
    dayScheduleEndPlusMaximumGap.valueOf() <= new Date(conflictingDaySchedule.start).valueOf()
  );
};

const hasOverlapWithConflictingSchedules = (daySchedule: DayScheduleRequest) =>
  daySchedule.conflictingDaySchedules.reduce(
    (hasConflictWithinOneHour, conflictingSchedule) =>
      hasConflictWithinOneHour || !haveMoreThanOneHourDifference(daySchedule, conflictingSchedule),
    false,
  );

const shouldCheckboxBeDisabled = (
  daySchedule: DayScheduleLight | DayScheduleRequest,
): daySchedule is DayScheduleRequest => {
  return (
    isScheduleWithConflictingDaySchedules(daySchedule) &&
    hasOverlapWithConflictingSchedules(daySchedule)
  );
};

const MissionScheduleDates = ({
  daySchedules,
  isEditable = false,
  isSplittable = true,
  checkedScheduleIds = [],
  onCheckboxChange,
}: MissionScheduleDatesProps) => {
  const listChange = useCallback(
    (item: ListItem) => {
      onCheckboxChange?.(
        listReducer(checkedScheduleIds, { type: ListActionKind.TOGGLE, payload: item }),
      );
    },
    [checkedScheduleIds, onCheckboxChange],
  );

  return (
    <IonList>
      {daySchedules.map((schedule) => (
        <IonItem key={schedule.id} className={styles.ionItem} lines="none">
          {isEditable ? (
            <IonCheckbox
              alignment="start"
              labelPlacement="end"
              className={styles.ionCheckbox}
              disabled={!isSplittable || shouldCheckboxBeDisabled(schedule)}
              checked={isSplittable ? checkedScheduleIds.includes(schedule.id) : true}
              onIonChange={() => listChange(schedule.id)}
            >
              <div className={styles.labelFirstLine}>
                <MissionScheduleDate
                  schedule={schedule}
                  shouldDateBeHighlighted={checkedScheduleIds.includes(schedule.id)}
                  shouldConflictBeHighlighted={
                    isScheduleWithConflictingDaySchedules(schedule) &&
                    !shouldCheckboxBeDisabled(schedule)
                  }
                />
                <MissionScheduleTime schedule={schedule} />
              </div>
              <MissionScheduleBreakDuration schedule={schedule} />
            </IonCheckbox>
          ) : (
            <IonLabel className="ion-no-margin">
              <div className={styles.labelFirstLine}>
                <MissionScheduleDate schedule={schedule} shouldDateBeHighlighted />
                <MissionScheduleTime schedule={schedule} />
              </div>
              <MissionScheduleBreakDuration schedule={schedule} />
            </IonLabel>
          )}
        </IonItem>
      ))}
    </IonList>
  );
};

export default MissionScheduleDates;
