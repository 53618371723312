enum ANALYTICS_LOG_EVENTS {
  ACCEPT_REQUEST_BUTTON_CLICK = 'accept_request_button_click',
  ACCEPT_UNAVAILABLE_REQUEST = 'accept_unavailable_request',
  AUTH_FAILURE = 'carer_auth_failure',
  AUTH_SUCCESS = 'carer_auth_success',
  CALENDAR_CLICK = 'calendar_click',
  CARER_MISSING_REQUIRED_DOCUMENTS_FOR_REQUEST = 'carer_missing_required_documents_for_request',
  EMPTY_REQUESTS_LIST = 'empty_requests_list',
  EMPTY_REQUESTS_LIST_WITH_FILTERS = 'empty_requests_list_with_filters',
  FACILITY_CARD_BUTTON_CLICK = 'facility_card_button_click',
  MISSIONS_FILTERS_CLICK = 'missions_filters_click',
  NEGOTIATE_REMUNERATION_CLICK = 'negotiate_remuneration_click',
  PENDING_CONTRACT_SIGNATURES_CARD_CLICK = 'pending_contract_signatures_card_click',
  PENDING_SCHEDULES_VALIDATION_CARD_CLICK = 'pending_schedules_validation_card_click',
  REQUESTS_FILTERS_CLICK = 'requests_filters_click',
  UNAVAILABLE_REQUEST_DETAIL = 'unavailable_request_detail',
}

// eslint-disable-next-line import/prefer-default-export
export { ANALYTICS_LOG_EVENTS };
